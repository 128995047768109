import axios from "axios";
import Enco from "security/enco"

const API_URL = "https://api.dhbtools.com/admin";

class AuthService {
  login(username, password) {
    return axios
      .post(API_URL + "/user/login", {
        username,
        password
      })
      .then(response => {
        if (response.data.token) {
          localStorage.setItem("user", Enco(JSON.stringify(response.data)));
        }

        return response.data;
      });
  }

  logout() {
    localStorage.removeItem("user");
    window.location = "/admin/login"
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem('user'));;
  }

}

export default new AuthService();
