import axios from 'axios';
import authHeader from './auth-header';

const API_URL = "https://api.dhbtools.com/admin/";

class MembersService {

  getMembers() {
    return axios.get(API_URL + "user", { headers: authHeader() });
  }

  createMember(obj) {
    return axios.post(API_URL + "user", obj, { headers: authHeader() })
  }

  updateMemberByMemberId(id, obj) {
    return axios.put(API_URL + "user/" + id, obj, { headers: authHeader() })
  }

  deleteMemberByMemberId(id){
    return axios.delete(API_URL + "user/" + id, { headers: authHeader() })
  }
  
}

export default new MembersService();